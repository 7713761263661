/**
 *  Formulář žádosti o akreditaci
 */

import React from "react";

import * as state from "../../../lib/state";

import WebRequest from "../../../components/templates/WebRequest";
import FormRadio from "../../../components/controls/Forms/FormRadio";
import CheckBoxRadioFrame from "../../../components/controls/CheckBoxRadioFrame";
import FormInput from "../../../components/controls/Forms/FormsInput";
import FormSelect from "../../../components/controls/Forms/FormSelect";
import FormTextArea from "../../../components/controls/Forms/FormsTextArea";
import FormFileUpload from "../../system/file/FormFileUpload";

import Button from "../../../components/controls/Button";
import FormsInput from "../../../components/controls/Forms/FormsInput";
import FileUpload from "../../../modules/system/file/FileUpload";
import FormsAutocomplete from "../../../components/controls/Forms/FormsAutocomplete";


interface WebRequestAccreditationPageProps {
	id?: string;
}

function WebRequestAccreditationPage(props: WebRequestAccreditationPageProps) {

	const model = state.useStateContext().webRequestAccreditation;
	const form = model.form;
	const context = state.useStateContext();
	const workplace = form.getField("workplace").value;

	async function addFile() {
		const attachments = form.getField("attachments").value;
		attachments.push({ attachment: undefined });
		form.setField("attachments", attachments);
	}

	async function removeFile() {
		const attachments = form.getField("attachments").value.filter((_, i) => form.getField("attachments").value.length - 1 != i);
		await context.standardDialogs.openYesNoDialog("Odstranit soubor?") === "yes"
			? form.setField("attachments", attachments)
			: undefined
	}

	async function setFile(index: number, value: any) {
		const items = form.getField("attachments").value;
		let item = items.filter((_, i) => index == i)[0] as any;
		item["attachment"] = value;
		form.setField("attachments", items);
	}

	return (
		<WebRequest id={props.id} model={model.common} title={model.stateContainer.get().requestType == "i" ? "ŽÁDOST O AKREDITACI TYPU I." : "ŽÁDOST O AKREDITACI TYPU II."} description="">


			<h2>Pro vzdělávací program v:</h2>

			<CheckBoxRadioFrame>
				<div className="row">
					<div className="col-md">
						<FormRadio
							form={form}
							field="type"
							option="child-stomatology"
							title="pedostomatologie"
						/>
					</div>
					<div className="col-md">
						<FormRadio
							form={form}
							field="type"
							option="paradantology"
							title="parodontologie"
						/>
					</div>
					<div className="col-md">
						<FormRadio
							form={form}
							field="type"
							option="stomatology-surgery"
							title="stomatochirurgie"
						/>
					</div>
				</div>
			</CheckBoxRadioFrame>

			<h2>Poskytovatel a pracoviště:</h2>

			<CheckBoxRadioFrame className="mb-3">
				<div className="row">
					<div className="col-12">
						<FormRadio
							form={form}
							field="request_workplace_type"
							option="existing_workplace"
							title="výběr nového poskytovatele a pracoviště z nabídky"
						/>
					</div>
					{/* <div className="col-12">
						<FormRadio
							form={form}
							field="request_workplace_type"
							option="not_found"
							title="pokud jste poskytovatele a pracoviště v nabídce nenalezli, založte nového poskytovatele nebo pracoviště"
						/>
					</div> */}
				</div>
			</CheckBoxRadioFrame>
			{form.getField("request_workplace_type").value == "existing_workplace" &&
				<>
					<FormsAutocomplete
						form={form}
						field="workplace"
						autocompleteProps={{
							trigger: "onfocus",
							loadItems: model.loadWorkplaces,
							getItemText: item => item?.name ?? "",
							getMenuItem: item => item?.name
						}}
					/>
					{workplace &&
						<div className="row mb-3">
							<div className="col-lg-6">
								<div className="row align-items-center">
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Poskytovatel</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.provider.name}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Název pracoviště</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.name}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Telefon</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.contact?.phone1}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">E-mail</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.contact?.email1}</span>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="row align-items-center">
									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">IČO</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.provider.registration_number}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Ulice a číslo</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.address?.street}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">Obec</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.address?.city}</span>
									</div>

									<div className="col-12 col-sm-4 col-md-5">
										<span className="box-profile__label">PSČ</span>
									</div>
									<div className="col-12 col-sm-8 col-md-7">
										<span className="box-profile__value">{workplace.address?.postcode}</span>
									</div>
								</div>
							</div>
						</div>
					}
				</>
			}

			{form.getField("request_workplace_type").value == "not_found" &&
				<>
					<p className="mt-3">
						Pokud jste poskytovatele zdravotní péče a pracoviště v nabídce nenalezli,
						založte nového poskytovale nebo pracoviště:
					</p>
					<div className="row mt-3 mb-1">
						<div className="col-lg-6">
							<FormsInput form={form} field="workplace_new_provider" />
							<FormsInput form={form} field="workplace_new_ico" />
							<FormsInput form={form} field="workplace_new_provider_address_street" />
							<FormsInput form={form} field="workplace_new_provider_address_city" />
							<FormsInput hideNumeric={true} form={form} inputProps={{ type: "number" }} field="workplace_new_provider_address_postcode" />
						</div>
						<div className="col-lg-6">
							<FormsInput form={form} field="workplace_new_name" />
							<FormsInput form={form} field="workplace_new_address_street" />
							<FormsInput form={form} field="workplace_new_address_city" />
							<FormsInput hideNumeric={true} form={form} inputProps={{ type: "number" }} field="workplace_new_address_postcode" />
							<FormsInput form={form} field="workplace_new_contact_phone" />
							<FormsInput form={form} field="workplace_new_contact_email" />
						</div>
					</div>
					<p className="mt-5 mb-3">
						<strong>
							Přiložte Rozhodnutí o udělení oprávnění k poskytování zdravotních služeb
							z KÚ nebo MHMP (v případě Prahy):
						</strong>
					</p>
					{form.getField("attachments").value.map((formFile, index) =>
						<div key={index} className="row">
							<div className="col-8 col-lg-10">
								<FileUpload
									key={index}
									onChange={(value) => setFile(index, value)}
									value={formFile.attachment}
								/>
							</div>
							<div className="col-4 col-lg-2">
								<Button className="d-block w-100" onClick={() => removeFile()}>Odebrat</Button>
							</div>
						</div>
					)}
					<Button onClick={addFile} className="mb-5">Přidat přílohu</Button>
				</>
			}

			{/* <h2 className="mb-5">Kontaktní osoba poskytovatele:</h2>

			<div className="row">
				<div className="col-md">
					<FormInput
						form={form}
						field="title_before"
					/>
				</div>
				<div className="col-md">
					<FormInput
						form={form}
						field="title_after"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-md">
					<FormInput
						form={form}
						field="name"
					/>
				</div>
				<div className="col-md">
					<FormInput
						form={form}
						field="lastname"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-md">
					<FormInput
						form={form}
						field="tel"
					/>
				</div>
				<div className="col-md">
					<FormInput
						form={form}
						field="email"
					/>
				</div>
			</div> */}

			<hr />

			<h2 className="mb-5">Zubní lékař odpovědný za průběh stáží:</h2>

			{/* <FormAutocomplete
				form={form}
				field="doctor"
				autocompleteProps={{}}
			/>  */}

			<div className="row">
				<div className="col-md">
					<FormInput
						form={form}
						field="identification_number"
					/>
				</div>
			</div>

			<div className="row">
				<div className="col-md">
					<FormInput
						form={form}
						field="doctor_title_before"
					/>
				</div>
				<div className="col-md">
					<FormInput
						form={form}
						field="doctor_title_after"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-md">
					<FormInput
						form={form}
						field="doctor_name"
					/>
				</div>
				<div className="col-md">
					<FormInput
						form={form}
						field="doctor_lastname"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-md">
					<FormInput
						form={form}
						field="doctor_email"
					/>
				</div>
			</div>

			<FormSelect
				form={form}
				field="certificate"
				options={[
					{
						title: "parodontologii",
						value: "parodontology"
					},
					{
						title: "stomatochirurgii",
						value: "stomatochirurgy"
					},
					{
						title: "pedostomatologii",
						value: "pedostomatology"
					}
				]}
			/>
			<div className="row">
				<div className="col-md">
					<FormInput
						form={form}
						field="specialist_specialization"
					/>
				</div>
				<div className="col-md">
					<FormInput
						inputProps={{
							type: "number"
						}}
						form={form}
						field="specialist_years"
					/>
				</div>
			</div>


			<h2 className="mb-5">Sylabus teoretické i praktické výuky</h2>

			<FormTextArea
				form={form}
				field="range"
			/>
			<FormTextArea
				form={form}
				field="theoretical_sylabus"
			/>
			<FormTextArea
				form={form}
				field="practical_sylabus"
			/>


			<em>
				<strong>Věcné a technické vybavení</strong> <br />
				Poskytovatel předložením této žádosti Kanceláři Komory prohlašuje, že pracoviště, na kterém bude uskutečňovat stáže, je kromě věcného a
				technického vybavení předepsaného obecně závaznými právními předpisy a řády Komory vybaveno nejméně (i) instrumentáriem a materiály standardně používanými při diagnostických, léčebných a regenerativních postupech, jejichž nácvik je součástí příslušného vzdělávacího
				programu, (ii) prostředky pro řešení náhlých příhod v ordinaci, (iii) zubním intraorálním rentgenovým přístrojem a ortopantomografem nebo
				CBCT přístrojem dostupným v budově, ve které se pracoviště nachází
			</em>


			{/* <h2 className="mb-3">K žádosti přiložte tyto přílohy:</h2>
			{model.stateContainer.get().requestType == "ii" &&
				<p>dokument ke stažení <a href="/images/Dohoda o akreditaci typ II.docx" download>zde</a></p>
			}
			<FormFileUpload
				form={form}
				field="file_information"
			/> */}

			{!props.id &&
				<div className="text-right mt-5">
					<div className="mb-2"><em><span className="form-item__required">*</span> Údaje označené hvězdičkou jsou povinné.</em></div>
					<em>Odesláním potvrzuji správnost údajů.</em><br /><br />
					<Button
						theme="primary"
						onClick={model.confirm}
						spinner={model.common.justSending()}
					>Odeslat</Button>
				</div>
			}


		</WebRequest>
	);

}


export default state.bindContainers(
	WebRequestAccreditationPage,
	ctx => ctx.webRequestAccreditation
);

